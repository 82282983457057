import { datadogLogs } from '@datadog/browser-logs';
import { isLocal } from '../constants/config';

export const loggerInfo = (message: string) => {
  if (isLocal()) {
    console.log({ message });
  } else {
    datadogLogs.logger.info(message);
  }
};

export const loggerError = (message: string, err: Error) => {
  if (isLocal()) {
    console.error(message, err);
  } else {
    datadogLogs.logger.error(message, {}, err);
  }
};
