import { envConfig } from '../constants/config';

export const level1ApiEndpoint = (): string => {
  return `${envConfig.backendUrl}/level1`;
};

export const level2ApiEndpoint = (): string => {
  return `${envConfig.backendUrl}/level2`;
};

export const level3ApiEndpoint = (): string => {
  return `${envConfig.backendUrl}/level3`;
};
