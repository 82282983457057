import { useEffect, lazy, useState, Suspense } from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { Organization } from './types';
import { firebaseAuth } from './firebaseAuth';
import { User } from 'firebase/auth';
import 'react-pdf/dist/cjs/Page/AnnotationLayer.css';
import 'react-pdf/dist/cjs/Page/TextLayer.css';
import { level2ApiEndpoint } from './utils/apiEndpointUrl';
import { requestToBackend } from './utils/rpcLogics';
import I18n from './i18n';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { envConfig, isLocal } from './constants/config';
import { loggerError } from './utils/logger';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const ResourceList = lazy(() => import('./pages/ResourceList'));
const SidebarLayout = lazy(() => import('./components/SidebarLayout'));
const Term = lazy(() => import('./pages/Term'));
const Welcome = lazy(() => import('./pages/Welcome'));
const PageNotFound = lazy(() => import('./components/PageNotFound'));
const SpecifiedCommercialTransactions = lazy(
  () => import('./pages/SpecifiedCommercialTransactions')
);
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const CompanyInfo = lazy(() => import('./pages/CompanyInfo'));
const LP = lazy(() => import('./pages/LP'));
const AccessLogExplorer = lazy(() => import('./pages/AccessLogExplorer'));
const OperationLogList = lazy(() => import('./pages/OperationLog'));
const OrganizationSetting = lazy(() => import('./pages/OrganizationSetting'));
const UserSetting = lazy(() => import('./pages/UserSetting'));
const ResourceAccessControl = lazy(
  () => import('./pages/ResourceAccessControl')
);
const ResourceAccessControlList = lazy(
  () => import('./pages/ResourceAccessControlList')
);
const SharingResource = lazy(() => import('./pages/SharingResource'));
const NoAuthLayout = lazy(() => import('./components/NoAuthLayout'));
const Receive = lazy(() => import('./pages/Receive'));

function App() {
  const currentUrl = new URL(window.location.href);
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
  const [currentOrganization, setCurrentOrganization] = useState<
    Organization | undefined
  >(undefined);
  const [organizationOptions, setOrganizationOptions] = useState<
    Organization[]
  >([]);
  const [isLoading, setLoading] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  firebaseAuth.onAuthStateChanged(function (user) {
    if (user) {
      setCurrentUser(user);
    }
  });

  setTimeout(() => {
    if (!isPageLoaded) {
      setLoading(false);
      setIsPageLoaded(true);
    }
  }, 1000);

  const ignoreAuthPath = [
    '/',
    '/company',
    '/privacy-policy',
    '/specified-commercial-transactions',
    '/receive',
    '/term',
  ];

  useEffect(() => {
    if (!currentUser || isLoading) {
      return;
    }
    if (ignoreAuthPath.includes(window.location.pathname)) {
      return;
    }
    (async () => {
      setLoading(true);
      const [response, error1] = await requestToBackend(
        currentUser,
        `${level2ApiEndpoint()}/user`,
        'GET'
      );
      if (error1) {
        loggerError(
          I18n.t(`error.${error1}`),
          new Error('failed to get user info')
        );
        return;
      }
      if (!response) {
        return;
      }
      const user: User = await response.json();
      if (!user) {
        return;
      }

      setCurrentUser(user);
      const [response2, error2] = await requestToBackend(
        currentUser,
        `${level2ApiEndpoint()}/organizations`,
        'GET'
      );
      if (error2) {
        loggerError(
          I18n.t(`error.${error2}`),
          new Error('failed to get organizations')
        );
        setIsPageLoaded(true);
        return;
      }
      if (!response2) {
        return;
      }
      const organizations: Organization[] = await response2.json();
      if (!organizations) {
        setIsPageLoaded(true);
        return;
      }
      setOrganizationOptions(organizations);
      const currentOrganizationId =
        currentUrl.searchParams?.get('organizationId');
      const currentOrganization = organizations.filter(
        (t) => t.id === currentOrganizationId
      )[0];

      if (currentOrganization) {
        setCurrentOrganization(currentOrganization);
      } else {
        currentUrl.searchParams.set('organizationId', organizations[0].id);
        window.location.href = currentUrl.href;
        setCurrentOrganization(organizations[0]);
      }
      setIsPageLoaded(true);
    })();
  }, [currentUser]);

  if (!isPageLoaded) {
    return <div></div>;
  } else if (currentUser && currentOrganization) {
    return (
      <HelmetProvider>
        <BrowserRouter>
          <Suspense fallback={<div></div>}>
            <Helmet>
              <meta name="robots" content="noindex" />
            </Helmet>
            <Routes>
              <Route element={<NoAuthLayout />}>
                <Route path="" element={<LP />} />
                <Route path="/company" element={<CompanyInfo />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/term" element={<Term />} />
                <Route
                  path="/specified-commercial-transactions"
                  element={<SpecifiedCommercialTransactions />}
                />
              </Route>
              <Route
                element={
                  <SidebarLayout
                    organizationOptions={organizationOptions}
                    currentOrganization={currentOrganization}
                    currentUser={currentUser}
                  />
                }
              >
                {/* <Route path="/" element={<DocumentList currentUser={currentUser} />} /> */}
                <Route path="/receive" element={<Receive />} />{' '}
                <Route
                  path="/welcome"
                  element={
                    <Welcome
                      currentOrganization={currentOrganization}
                      currentUser={currentUser}
                    />
                  }
                />
                <Route
                  path="/tracking"
                  element={
                    <ResourceAccessControlList
                      currentOrganization={currentOrganization}
                      currentUser={currentUser}
                    />
                  }
                />
                <Route
                  path="/tracking/:resourceId/resourceAccessControls"
                  element={
                    <ResourceAccessControl
                      currentOrganization={currentOrganization}
                      currentUser={currentUser}
                    />
                  }
                />
                <Route
                  path="/tracking/:resourceId/resourceAccessControls/:resourceAccessControlId"
                  element={
                    <SharingResource
                      currentOrganization={currentOrganization}
                      currentUser={currentUser}
                    />
                  }
                />
                <Route
                  path="/access-log-explorer"
                  element={
                    <AccessLogExplorer
                      currentUser={currentUser}
                      currentOrganization={currentOrganization}
                    />
                  }
                />
                <Route
                  path="/resources"
                  element={
                    <ResourceList
                      currentUser={currentUser}
                      currentOrganization={currentOrganization}
                    />
                  }
                />
                <Route
                  path="/user-setting"
                  element={
                    <UserSetting
                      currentUser={currentUser}
                      currentOrganization={currentOrganization}
                    />
                  }
                />
                <Route
                  path="/organization-setting"
                  element={
                    <OrganizationSetting
                      currentUser={currentUser}
                      currentOrganization={currentOrganization}
                    />
                  }
                />
                <Route
                  path="/operation-log"
                  element={
                    <OperationLogList
                      currentUser={currentUser}
                      currentOrganization={currentOrganization}
                    />
                  }
                />
              </Route>

              <Route
                path="*"
                element={
                  <Navigate
                    replace
                    to={`/resources?organizationId=${currentOrganization.id}`}
                  />
                }
              />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </HelmetProvider>
    );
    // アカウントを作った直後
  } else {
    return (
      <HelmetProvider>
        <BrowserRouter>
          <Suspense fallback={<div></div>}>
            <Helmet>
              <meta name="robots" content="noindex" />
            </Helmet>
            <Routes>
              <Route element={<NoAuthLayout />}>
                <Route path="/receive" element={<Receive />} />
                <Route path="/company" element={<CompanyInfo />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/term" element={<Term />} />
                <Route
                  path="/specified-commercial-transactions"
                  element={<SpecifiedCommercialTransactions />}
                />
                <Route path="" element={<LP />} />
                <Route path="*" element={<PageNotFound />} />
              </Route>
            </Routes>
          </Suspense>
        </BrowserRouter>
      </HelmetProvider>
    );
  }
}

export default App;
