import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {ja} from "./locales/ja";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {

  ja: {
    translation: {
      ...ja
    },
  },
  react: {
    wait: true,
  },
};

export const supportLanguages = ['ja']
const currentLanguage = () => {
  let lang = localStorage ? localStorage.getItem("language"): 'ja';
  if (supportLanguages.includes(lang)) {
    return lang
  }
  return 'ja'
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: currentLanguage(),
    languages: supportLanguages,
    interpolation: {
      escapeValue: false,
    },
    load: 'current',
    fallbackLng: 'ja',
  });

export default i18n;
