import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { envConfig } from './constants/config';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: envConfig.firebaseConfig.apiKey,
  authDomain: envConfig.firebaseConfig.authDomain,
  projectId: envConfig.firebaseConfig.projectId,
  storageBucket: envConfig.firebaseConfig.storageBucket,
  messagingSenderId: envConfig.firebaseConfig.messagingSenderId,
  appId: envConfig.firebaseConfig.appId,
  measurementId: envConfig.firebaseConfig.measurementId,
};

const app = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth(app);
export const analytics = getAnalytics(app);
